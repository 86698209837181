import React from 'react';

function NotFoundPage() {
  return (
    <React.Fragment>
      <div className="App">
        <main className="App-header">
          <p>
            404 Not found
          </p>
        </main>
      </div>
    </React.Fragment>
  );
}

export default NotFoundPage;
