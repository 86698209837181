import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppAppBar from './views/AppAppBar';
import Copyright from './views/Copyright';

import HomePage from './pages/HomePage/HomePage';
import { Path, pathsWithoutNav } from './routes';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';

import './App.css';

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const location = useLocation();
  const isProd = process.env.NODE_ENV === 'production';

  const shouldShowNavigation = !pathsWithoutNav.some(path => location.pathname.startsWith(path));

  return (
    <ThemeProvider theme={defaultTheme}>
      {shouldShowNavigation && <AppAppBar />}

      <Routes>
        {/* static routes */}
        <Route path={Path.Home} element={<HomePage />} />
        <Route path={Path.All} element={<NotFoundPage />} />
      </Routes>

      <Copyright />
      {!isProd && <p style={{textAlign: 'center'}}>PROD</p>}
    </ThemeProvider>
  );
}

export default App;
