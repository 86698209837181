export type TPage =
  | 'Home'
  | 'All';

export const Path: Record<TPage, string> = {
    Home: `/`,
    All: '/*',
};

export const pathsWithoutNav = [
    Path.All,
];