import React from 'react';

function HomePage() {
  return (
    <React.Fragment>
      <div className="App">
        <main className="App-header">
          <p>
            Under construction....
          </p>
        </main>
      </div>
    </React.Fragment>
  );
}

export default HomePage;
