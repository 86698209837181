import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const getBaseName = () => {
  return process.env.BASE_NAME || '/';
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={getBaseName()}>
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
